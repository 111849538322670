<div id="intro-section" class="view">
  <video class="video-intro" style="height: 900px" poster="https://clubb2b.com.br/images/main/background.jpg"
    playsinline oncanplay="this.play()" onloadedmetadata="this.muted=true" loop>
    <source src="https://petadote.com/videos/petadote.mp4" type="video/mp4" />
  </video>
  <div class="mask rgba-gradient d-md-flex justify-content-center align-items-center overflow-auto">
    <div class="container px-md-3 px-sm-0">
      <div class="row wow fadeIn">
        <div
          class="col-md-12 mb-4 white-text text-center mb-0 mt-5 pt-md-5 pt-5 wow fadeIn">
          <h1 class="display-3 font-weight-bold sombra">
            <a class="font-weight-bold amber-text sombra">Pet</a>
            <a class="font-weight-bold teal-text sombra">Adote</a>
          </h1>
          <h1 class="font-weight-bold white-text sombra">
            O Amor de um
            <a class="font-weight-bold amber-text sombra">PET</a>
            é
            <a class="font-weight-bold red-text sombra">INCONDICIONAL</a>
          </h1>
          <hr class="hr-light my-4 w-95" />
          <h4 class="subtext-header mt-2 mb-4 text-justify">
            Este é um site sem fins lucrativos e uma iniciativa da
            <a class="font-weight-bold indigo-text sombra">StartUp </a>
            <a class="font-weight-bold teal-text sombra">Club</a>
            <a class="font-weight-bold yellow-text sombra">B</a>
            <a class="font-weight-bold blue-text sombra">2</a>
            <a class="font-weight-bold white-text sombra">B </a>
            <a class="font-weight-bold teal-text sombra">Group</a>. Nós da
            <a class="font-weight-bold teal-text sombra">Club</a>
            <a class="font-weight-bold yellow-text sombra">B</a>
            <a class="font-weight-bold blue-text sombra">2</a>
            <a class="font-weight-bold white-text sombra">B</a>, sensibilizados
            com o abandono nas ruas de muitos animais, e com a dificuldade das
            pessoas em adotarem ou doarem seu
            <a class="font-weight-bold amber-text sombra">PET</a>, bem como a
            falta de visibilidade das Casas de Acolhimento (Abrigos) de
            <a class="font-weight-bold amber-text sombra">PETs</a>, decidimos
            dar uma mãozinha para ajudar os
            <a class="font-weight-bold amber-text sombra">PETs</a>
            abandonados a encontrarem um lar ou uma Casa de Acolhimento.
            <p class="font-weight-bold text-center mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra">
              Em breve estaremos online para servi-los!
            </p>
            <h1 *ngIf="innerWidth > 414" class="display-3 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra">
              SITE EM CONSTRUÇÃO
            </h1>
            <h1 *ngIf="innerWidth <= 414" class="display-5 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra">
              SITE EM CONSTRUÇÃO
            </h1>
            <div *ngIf="innerWidth > 414" class="timer">
              <h4 class="font-weight-bold red-text sombra"> Contagem Regressiva </h4>
              <span id="days" class="font-weight-bold teal-text sombra"> {{daysToDday}} </span>
              <a class="font-weight-bold sombra">Dia(s)</a>
              <span id="hours" class="font-weight-bold teal-text sombra"> {{hoursToDday}} </span>
              <a class="font-weight-bold sombra">Hs</a> 
              <span id="minutes" class="font-weight-bold teal-text sombra"> {{minutesToDday}} </span>
              <a class="font-weight-bold sombra">Min</a>
              <span id="seconds" class="font-weight-bold teal-text sombra"> {{secondsToDday}} </span>
              <a class="font-weight-bold sombra">Seg</a>
            </div>
            <div *ngIf="innerWidth <= 414" class="timer-mobile">
              <h4 class="font-weight-bold red-text sombra"> Contagem Regressiva </h4>
              <span id="days" class="font-weight-bold teal-text sombra"> {{daysToDday}} </span>
              <a class="font-weight-bold sombra">Dia(s)</a>
              <span id="hours" class="font-weight-bold teal-text sombra"> {{hoursToDday}} </span>
              <a class="font-weight-bold sombra">Hs</a> 
              <span id="minutes" class="font-weight-bold teal-text sombra"> {{minutesToDday}} </span>
              <a class="font-weight-bold sombra">Min</a>
              <span id="seconds" class="font-weight-bold teal-text sombra"> {{secondsToDday}} </span>
              <a class="font-weight-bold sombra">Seg</a>
            </div>
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
